import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import ContactUs from "../components/pages/contact/ContactUs"
import Support from "../components/pages/contact/Support"
import SEO from "../components/Seo"
import "./contact.scss"

const Contact = () => (
  <Layout>
    <SEO
      title="Contact"
      description="Formulaire de contact pour les entreprises, les distributeurs et les particuliers."
      keywords={[
        "contact",
        "entreprise",
        "distributeur",
        "particulier",
        "consigne",
        "zéro",
        "déchet",
      ]}
    />
    <div className="contact__background">
      <NavBar activePage="contact" />
      <ContactUs />
      <Support />
      <Footer></Footer>
    </div>
  </Layout>
)

export default Contact
