import * as React from "react"
import {
  DistributeursCard,
  EntreprisesCard,
  ParticuliersCard,
} from "./ContactCard"
import "./ContactUs.scss"

const ContactUs: React.FunctionComponent<{}> = () => {
  return (
    <section className="contactus padding">
      <h1 className="contactus__heading heading heading--1">contactez-nous</h1>
      <section className="contactus__items">
        <EntreprisesCard />
        <DistributeursCard />
        <ParticuliersCard />
      </section>
    </section>
  )
}

export default ContactUs
