import { Link } from "gatsby"
import * as React from "react"
import "./ContactCard.scss"

interface ContactCardProps {
  title: "entreprises" | "distributeurs" | "particuliers"
  content: string
}

const ContactCard: React.FunctionComponent<ContactCardProps> = ({
  title,
  content,
}) => {
  return (
    <article className="contactus__item">
      <h2 className="heading heading--2 small heading--svg heading--svg-underline">
        {title}
      </h2>
      <p className="paragraph paragraph--black">{content}</p>
      <Link className="button button--color-pink" to={`/contact/${title}`}>
        C'est par ici !
      </Link>
    </article>
  )
}

export default ContactCard

export const EntreprisesCard: React.FunctionComponent = () => {
  return (
    <ContactCard
      title="entreprises"
      content="“Je veux engager mes collaborateurs et mes clients dans la réduction
            des déchets.”"
    />
  )
}

export const DistributeursCard: React.FunctionComponent = () => {
  return (
    <ContactCard
      title="distributeurs"
      content="“Je suis un distributeur, un restaurateur ou une marque engagée et je
        veux développer une offre de consigne.”"
    />
  )
}

export const ParticuliersCard: React.FunctionComponent = () => {
  return (
    <ContactCard
      title="particuliers"
      content="“Je veux faire découvrir La Consigne GreenGo à mon entreprise ou à
          mon entourage.”"
    />
  )
}
