import { Link } from "gatsby"
import * as React from "react"
import "./Support.scss"

const Support: React.FunctionComponent = () => {
  return (
    <section className="support">
      <div className="support__question">
        <h2>
          Vous avez des questions et souhaitez être mis en relation avec le
          Service Client ?
        </h2>
      </div>
      <div className="support__link">
        <p>
          C’est <Link to="/contact/particuliers#support">par ici !</Link>
        </p>
      </div>
    </section>
  )
}

export default Support
